export const studentprojects = [
    // {
    //     title: "Binary Neural Networks for Eye Tracking on Neuromorphic Event Data",
    //     description:
    //         "Develop a lightweight and high-speed architecture optimized for embedded hardware using BNNs for eye tracking on event-based data.",
    //     keywords: ["Computer Vision", "Binary Neural Network"],
    //     contact: "Pietro Bonazzi (pbonazzi@ethz.ch)",
    //     moreInfo: "https://sirop.org/app/b6155b63-5184-41a5-b32c-d5464cb50983",
    // },
    {
        title: "TinySAM2: Segmenting Videos and Images with Sony IMX500",
        description:
            "Adapt video and image segmentation algorithms for resource-constrained AI-in-sensor devices like the Sony IMX500.",
        keywords: ["Computer Vision", "Segmentation"],
        partner: "ETH Zurich - Sony Semiconductor Europe",
        teamSize: 2,
        teamSizeNow: 2,
        contact: "Pietro Bonazzi (pbonazzi@ethz.ch)",
        moreInfo: "https://sirop.org/app/22dc4ccb-de73-412f-97f6-39c2a1e60bb3",
    },
    {
        title: "Transformer-based model for Gesture Recognition",
        description:
            "Develop a transformer-based model for gesture recognition which can outperform current SOTA methods on a pre-selected dataset.",
        keywords: ["Times-Series", "Gesture Recognition"],
        partner: "ETH Zurich - Huawei Technologies, Zurich RC",
        teamSize: 1,
        teamSizeNow: 1,
        contact: "Pietro Bonazzi (pbonazzi@ethz.ch)",
        moreInfo: "https://sirop.org/app/bd329c39-3030-441d-a38c-48f12d282af1?_k=NOpob7auLgtRmK23",
    },
    {
        title: "Convolutional Differentiable Logic Gate Networks for Object Localization",
        description:
            "Develop logic gate networks (LGNs) for object localization and regression-based computer vision tasks.",
        keywords: ["Computer Vision", "Logic Networks"],
        contact: "Pietro Bonazzi (pbonazzi@ethz.ch)",
        partner: "ETH Zurich",
        teamSize: 1,
        teamSizeNow: 0,
        moreInfo: "https://sirop.org/app/fdc257f9-12f2-4f6c-875b-dc337525c9ad",
    },
    // {
    //     title: "EEG-based Brain-Computer Interface for post-stroke neurorehabilitation",
    //     description:
    //         "Incorporate real-time EEG data acquisition, neural network (NN) inference on a low-power GAP8 microcontroller.",
    //     keywords: ["EEG", "BCI"],
    //     partner: "ETH Zurich - Slovak Academy of Sciences",
    //     teamSize: 0,
    //     contact: "Tommaso Polonelli (topolonelli@ethz.ch)",
    //     moreInfo: "https://sirop.org/app/5bcfef95-e464-4311-9558-ba84ccfb0597?_k=-f61d6ICOnQflPyj",
    // },
];